import Header from "../components/header";
import Subscription from "../components/subscription";
import Footer from "../components/footer";
import GalleryTab from "../components/PageComponents/GalleryTab";


export default function Gallery(){
    return(
        <>
                <Header/>
                <GalleryTab />
                <Subscription />
                <Footer />
        </>
    )
}