export default function AboutContent(){
    return(
        <>
            <sectio className="text-center container about-section">
                <div className="container py-5">
                    <h1 className="yello-text pb-2">About Us</h1>
                    <p className="white-text">A home/office/land of your own has always been a symbol of freedom. It is all about choice and the ability to live life or work on personal terms. At Ranav Group, it’s our collective ability — to build homes/villas/commercial spaces and offer premium plots of your choice in the most sought-after locales — that drives our business. Proudly Indian, we are working hard to be among the top construction companies in Bangalore through qualitative, luxurious and reliable gated communities, open plots development and commercial projects.So if you are looking for one of the top builders in Bangalore or new upcoming gated community projects in Bangalore, your search ends here.</p>
                </div>
            </sectio>
        </>
    )
}