export default function MapComponent(){
    const GioLocation = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2425779741784!2d77.7035561748927!3d12.95632358735768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1231825f0e71%3A0xd564522d3f3956e6!2sRanav%20Group!5e0!3m2!1sen!2sin!4v1726640768002!5m2!1sen!2sin"
    return(
        <>
            <div className="contact-address">
        <div className="row px-5 py-2 ">
          <div className="text-center">
            <p className="askd-txt m-0">Contact Us</p>
            <span className="text-white m-0">
              Questions, comments, or suggestions? Simply fill in the form and
              we’ll be in touch shortly.
            </span>
          </div>
          <div className=" col-md-6 px-5 py-5">
            <h5 className="white-text"> Get In Touch</h5>
            <address>
              <p>
                <i className="bi bi-telephone"></i>{" "}
                <a href="tel:+80866662666">80 2666 2666</a>
              </p>
              <p>
                <i class="bi bi-geo-alt"></i> No. 21/1, Marthahalli Flyover
                Service Road, Opp. Purva Fountain Square Apartment, Bengaluru,
                Karnataka - 560 037.
              </p>

              <p>
                <i className="bi bi-envelope"></i>{" "}
                <a href="mailto:info@example.com">info@example.com</a>
              </p>
            </address>
          </div>
          <div className="col-md-6 py-5">
            <iframe
              src={GioLocation}
              width="500"
              height="250"
              style={{ border: 0, borderRadius: "15px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
        </>
    )
}