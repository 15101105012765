import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import { Tabs, Tab, Pagination } from 'react-bootstrap';

export default function GalleryTab() {
    const [activeTab, setActiveTab] = useState('ranav1');
    const [currentPage, setCurrentPage] = useState(1);

    const images = {
        ranav1: [ /* Array of images for first tab */ ],
        ranav2: [ /* Array of images for second tab */ ],
        ranav3: [ /* Array of images for third tab */ ],
        ranav4: [ /* Array of images for fourth tab */ ],
    };

    const itemsPerPage = 9;
    const totalItems = images[activeTab].length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleTabChange = (key) => {
        setActiveTab(key);
        setCurrentPage(1); // Reset to the first page when the tab changes
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedImages = images[activeTab].slice(startIndex, startIndex + itemsPerPage);

    // Masonry breakpoints
    const breakpointColumns = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
    };

    return (
        <div className="gallery-tab-container">
            <Tabs
                id="gallery-tabs"
                activeKey={activeTab}
                onSelect={handleTabChange}
                className="mb-3"
            >
                <Tab eventKey="ranav1" title="RANAV Tranquil Haven">
                    {/* Content for the first tab */}
                </Tab>
                <Tab eventKey="ranav2" title="Ranav opulent Heights">
                    {/* Content for the second tab */}
                </Tab>
                <Tab eventKey="ranav3" title="RANAV Elegancy">
                    {/* Content for the third tab */}
                </Tab>
                <Tab eventKey="ranav4" title="RANAV Colonial Haven">
                    {/* Content for the fourth tab */}
                </Tab>
            </Tabs>

            <Masonry
                breakpointCols={breakpointColumns}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {paginatedImages.map((image, index) => (
                    <div key={index} className="masonry-item">
                        <img src={image} alt={`Gallery ${index}`} className="img-fluid" />
                    </div>
                ))}
            </Masonry>

            <Pagination className="justify-content-center mt-4">
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            </Pagination>
        </div>
    );
}
