import PropertyIcon from '../../assets/icons/property.png';
import villaIcon from '../../assets/icons/villas.png';
import trustIcon from '../../assets/icons/trust.png';
import family from '../../assets/family.png';
import ResidentialDevelpment from '../../assets/icons/residentialDevelopment.png';
import commercialDevelopment from '../../assets/icons/commercialDevelopment.png';
import villasConstructions from '../../assets/icons/villasConstructions.png';
import farm from '../../assets/icons/farm.png';


export default function TrustRanav(){
    return(
        <>
            <sectio className="text-center container">
                <div className="container">
                    <h1 className="yello-text pb-2">Trust RANAV</h1>
                    <p className="white-text text17">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </sectio>
            <section className="container">
                <div className="container row">
                    <div className="col-md-4">
                        <div className="text-center trust-cards">
                            <img src={PropertyIcon} />
                            <h6 className='yello-text pt-4 pb-2'>
                                Wide Range of Properties
                            </h6>
                            <p className='white-text'>
                                We offer expert legal help for all related property-items in Bengaluru.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="text-center trust-cards pt30">
                            <img src={villaIcon} />
                            <h6 className='yello-text pt-4 pb-2'>
                                Buy luxurious Villas
                            </h6>
                            <p className='white-text'>
                                We sell villas at the best market price and very quickly as well.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="text-center trust-cards pt30">
                            <img src={trustIcon} />
                            <h6 className='yello-text pt-4 pb-2'>
                                Trusted by Thousands
                            </h6>
                            <p className='white-text'>
                                We offer you free consultancy to get a loan for your new home.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className=''>
                <div className='row highlites'>
                        <div className='col-md-5 highlites-img offset-md-1 d-flex justify-content-center align-items-center pt-5'>
                            <img src={family} />
                        </div>                        
                        <div className='col-md-6'>
                            <div className='row py-5'>
                                <div className='col-md-5 offset-md-1'>
                                    <div className="trust-cards pt30">
                                        <img src={ResidentialDevelpment} />
                                        <h6 className='yello-text pt-4 pb-2'>
                                            Residential Development
                                        </h6>
                                        <p className='white-text pb-4'>
                                            RANAV aim is to emerge as one of the leading builders in Bangalore by creating opportunities for urban families & residential development.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className="trust-cards pt30">
                                        <img src={commercialDevelopment} />
                                        <h6 className='yello-text pt-4 pb-2'>
                                            Commercial Development
                                        </h6>
                                        <p className='white-text pb-4'>
                                            RANAV Group, believes in providing a sense of pride in people through commercial developments. Ranav aim at making life more easy.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-5 offset-md-1'>
                                    <div className="trust-cards pt30">
                                        <img src={villasConstructions} />
                                        <h6 className='yello-text pt-4 pb-2'>
                                            Villa’s Constructions
                                        </h6>
                                        <p className='white-text'>
                                            RANAV Group is offering the upcoming gated community projects in Bangalore through ultra-luxe private villas with avant-garde amenities and all the prestige inclusions.Make your dream of owning a villa come true through pre launch gated community projects in Bangalore.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className="trust-cards pt30">
                                        <img src={farm} />
                                        <h6 className='yello-text pt-4 pb-2'>
                                            FARM/OPEN PLOT'S DEVELOPMENT
                                        </h6>
                                        <p className='white-text'>
                                            Backed by its talented team's experience and expertise across all facets of residential development, Ranav Group offers a choice of prime land investment opportunities through its farm/open plots development solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
        </>
    )
} 