import Header from "../components/header";
import Subscription from "../components/subscription";
import Footer from "../components/footer";
import LeaderConsulting from "../components/PageComponents/LeaderConsulting";
import HomeBanner from "../components/Banners/home_banner";


export default function Leaders(){
    return(
        <>
                <Header/>
                <HomeBanner />
                <LeaderConsulting />
                <Subscription />
                <Footer />
        </>
    )
}