import React, { useState } from "react";
import Header from "../components/header";
import Subscription from "../components/subscription";
import Footer from "../components/footer";
import logo from "../assets/contact.png"
import MapComponent from "../components/PageComponents/MapComponent";

export default function Contact() {
  // useState should be inside the component
  const [data, setData] = useState({
    username: "",
    phoneno: "",
    emailid: "",
    textar: "",
  });

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value, // Dynamically set form data
    });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form Data Submitted:", data);
  };

  return (
    <>
      <Header />
      <div className="banner-space"></div>

      <div className="container-fluid px-5">
        <div className="row">
          <div className="col-md-6 form-container">
            <h1 className="contact-txt">Contact Us</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  className="fpe-inputs"
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Full Name*"
                  value={data.username} // Bind input to state
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="fpe-inputs"
                  type="number"
                  name="phonenumber"
                  id="phoneno"
                  placeholder="Phoneno*"
                  value={data.phoneno}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="fpe-inputs"
                  type="email"
                  name="emailid"
                  id="emailid"
                  placeholder="Email ID*"
                  value={data.emailid}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  className="txt-ar"
                  name="text"
                  placeholder="message*"
                  //   value={data.textar}
                  rows="4"
                  cols="50"
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <button type="submit" className="sub-btn">
                Submit
              </button>
            </form>
          </div>
          <div className="col-md-6">
            <img src={logo} alt="" className="contact-img" />
          </div>
        </div>
      </div>

      <div className="accordian-section">
        <div className="text-center">
          <p className="askd-txt m-0">Frequently asked Questions</p>
          <span className="text-white m-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </span>
        </div>
        <div className="accordians">
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  How we can help you?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div className="accordion-body">
                  Pellentesque vehicula eros neque, maximus mattis est sagittis
                  Nulla facilisi. In sed pretium metus. Proin pretium id urna
                  sit amet tincidunt.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  How we can help you?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div className="accordion-body">
                  Pellentesque vehicula eros neque, maximus mattis est sagittis
                  Nulla facilisi. In sed pretium metus. Proin pretium id urna
                  sit amet tincidunt.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  How we can help you?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div className="accordion-body">
                  Pellentesque vehicula eros neque, maximus mattis est sagittis
                  Nulla facilisi. In sed pretium metus. Proin pretium id urna
                  sit amet tincidunt.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MapComponent />
      <Subscription />
      <Footer />
    </>
  );
}
